
import { Component, OnInit } from '@angular/core';
import { APP_URLS, tenantInfo } from '../../Models/url';
import { MyProfileService } from '../../services/my-profile.service';
import html2canvas from 'html2canvas';
import * as jspdf from 'jspdf';
import * as moment from 'moment';
import { CommonService } from '../../services/common.service';
import { DomSanitizer, SafeUrl } from '@angular/platform-browser';
import { Router, ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-certificates-tab',
  templateUrl: './certificates-tab.component.html',
  styleUrls: ['./certificates-tab.component.css']
})
export class CertificatesTabComponent implements OnInit {

  CourseCertificate = [];
  TestCertificate = [];
  LmsCertificate = [];
  DisplayName = "";
  certificateTemplate : any;
  CertificateView: boolean = false;
  certificates = [];
  LmsCertificates = [];
  CourseCertificates = [];
  snippet: HTMLDivElement;
  responce: any;
  originalcertificate: any;
  latestval = "";
  previousdata: any;
  viewLmsCertificate = [];
  viewCourseCertificate = [];
  tenantSettingsData:any;

  isNoLmsCertificateData : boolean = false;
  isNoTestCertificateData : boolean = false;
  isNoCourseCertificateData : boolean = false;
  showResultSummaryDialog: boolean = false;


  constructor(public service: MyProfileService,private sanitizer: DomSanitizer,private router: Router,
    public cms: CommonService,) {
  }

  ngOnInit(): void {
    this.service.setSidemenuTab("certificates");
    var first_name = JSON.parse(localStorage.getItem('profilesummaryData')).first_name;
    var middle_name = JSON.parse(localStorage.getItem('profilesummaryData')).middle_name;
    var last_name = JSON.parse(localStorage.getItem('profilesummaryData')).last_name;
    this.DisplayName= first_name +' ' + middle_name +' '+ last_name
    this.getCertificateData()
    this.getTenantSettings()
  }

  resultSummary:any;
  showResultSummary( resultData : any, type : any ){
    this.resultSummary  = [];
    window.scroll(0,0);
    this.resultSummary = resultData[resultData.exam_type];
    this.showResultSummaryDialog = !this.showResultSummaryDialog;
  }

  async getTenantSettings() {
    let url = APP_URLS.tenantSettings
    await this.service.getCallWithHeaders(url).subscribe((res: any) => {
       this.tenantSettingsData = res.details[0];
    })
  }

  getCertificateData() {
    let url = APP_URLS.ProfileCertificates;
      // this.cms.loaderSet(true);
      this.service.getCallWithHeaders(url).subscribe((res: any) => {
      // this.cms.loaderSet(false);
      this.LmsCertificate = res.lms_certificate;
      this.TestCertificate = res.test_certificate;
      this.CourseCertificate = res.course_certificate;

      this.isNoLmsCertificateData = this.LmsCertificate.length ? false : true;
      this.isNoTestCertificateData = this.TestCertificate.length ? false : true;
      this.isNoCourseCertificateData = this.CourseCertificate.length ? false : true;

    },err => {
      // this.cms.loaderSet(false);
    })
  }

  setTestCertificate(){
    this.TestCertificate.forEach((x:any,i)=>{
    this.getCertificateFun(x.coursetest.name,"test",i,this.previousdata)
    this.previousdata = x.coursetest.name
    })
    this.setLmsCertificate();
  }

  setLmsCertificate(){
    this.LmsCertificate.forEach((x:any,i)=>{
      this.getCertificateFun(x.chapter.name,"lms",i,this.previousdata)
      this.previousdata = x.coursetest.name
    });
    this.setCourseCertificate();
  }
  setCourseCertificate(){
    this.CourseCertificate.forEach((x:any,i)=>{
      this.getCertificateFun(x.chapter.name,"lms",i,this.previousdata)
      this.previousdata = x.coursetest.name
    })
  }

  getCertificate() {
    //this.cms.loaderSet(true);
    let url = APP_URLS.certificateTemplate;
    this.service.getCallWithHeaders(url)
    .subscribe((res: any) => {
    //  this.cms.loaderSet(false);

    this.originalcertificate = res
     this.responce = res
      this.snippet = document.createElement("div");
      this.snippet.innerHTML = res.resTemp.template_text_view;
     },err=>{
      //this.cms.loaderSet(false);

     })
  }


  getCertificateFun(data,type,i,previousdata) {
    Array.from(this.snippet.querySelectorAll('img')).forEach(function (ele) {

      var text = ele.src
      var text2=ele.src
      let logo = tenantInfo.logoUrl;
      let backgroundlogo = '../../../assets/images/certificatebg.png'
      let val = location.origin+"/[sitelogo]"
      let val2 = location.origin+"/[backgroundlogo]"
      if (ele.src == val) {
        text = text.replace(text, logo)
        ele.src = text

      }
      if (ele.src == val2) {
        text = text.replace(text2, backgroundlogo)
        ele.src = text
      }
    });
    Array.from( this.snippet.querySelectorAll('div')).forEach(function (ele) {
      var text = ele.textContent
      var text2 = ele.textContent
      var first_name = JSON.parse(localStorage.getItem('profilesummaryData')).first_name;
      var middle_name = JSON.parse(localStorage.getItem('profilesummaryData')).middle_name;
      var last_name = JSON.parse(localStorage.getItem('profilesummaryData')).last_name;
      var name = first_name +' ' + middle_name +' '+ last_name
      const date = new Date()
      if (ele.textContent == "[studentname]") {
        text = text.replace(text, name)
        ele.textContent = text
      }
      if (ele.textContent == "[taskname] test" || ele.textContent == previousdata ) {
        text = text.replace(text2, data)
        ele.textContent = text;
      }
    });
    Array.from( this.snippet.querySelectorAll('td')).forEach(function (ele) {
      var text3 = ele.textContent
      var text = ele.textContent
      const date = moment().format("YYYY-MM-DD")
      if (ele.textContent == "[date]") {
        text = text.replace(text3, date)
        ele.textContent = text
      }
    });
    this.responce.resTemp.template_text_view =  this.snippet.innerHTML
    this.certificateTemplate = this.responce.resTemp.template_text_view;
    if(type == 'test'){
      let obj={
        id : 'test'+i,
        template :this.sanitizer.bypassSecurityTrustHtml(this.responce.resTemp.template_text_view)
      }
      this.certificates.push(obj)
      this.viewCertificate.push(this.responce.resTemp.template_text_view)
    }
    if(type == 'lms'){
      let obj={
        id : 'test'+i,
        template :this.sanitizer.bypassSecurityTrustHtml(this.responce.resTemp.template_text_view)
      }
      this.LmsCertificates.push(obj)
      this.viewLmsCertificate.push(this.responce.resTemp.template_text_view)
    }
    if(type == 'course'){
      let obj={
        id : 'course'+i,
        template :this.sanitizer.bypassSecurityTrustHtml(this.responce.resTemp.template_text_view)
      }
      this.CourseCertificates.push(obj)
      this.viewCourseCertificate.push(this.responce.resTemp.template_text_view)
    }
  }

  viewCertificate = []

test(){
  // this.cms.loaderSet(false);
}
  download(id) {
    // this.cms.loaderSet(true);
 setTimeout(()=>{
  this.test()
  var data = document.getElementById(id);
    html2canvas(data).then(canvas => {

      var imgWidth =  300;
      var pageHeight = '100%';
      var imgHeight = 350 ;
      var heightLeft = imgHeight;
      const contentDataURL = canvas.toDataURL('image/png')
      let pdf = new jspdf(); // A4 size page of PDF
      var position = 0;
      pdf.addImage(contentDataURL, 'PNG', 0, position,imgWidth )
      pdf.save('MYPdf.pdf'); // Generated PDF
    });
  }, 1000);


  }


  getCertificateTypeForMytest( chapterType : any, testType : any ){
    var certificateType = '';
    if( chapterType == 'independent' && testType == 'test'){
       certificateType = 'Test Certificate - Test'
     } else if( chapterType == 'independent' && testType == 'qatest' ){
       certificateType = 'Test Certificate - Test QA'
     }
     return certificateType;
  }

  openCertificate(data,type){
    var obj;
    if(type == 'test'){
      obj = {
        studentname : data.student.first_name,
        course : data.coursetest.name,
        certificate_id : data._id,
        updated : data.updated,
        signature : this.tenantSettingsData.settings.signature,
        certificate_type : this.getCertificateTypeForMytest( data.coursetest.chapter_type, data.assignment_id.exam_type ),
        requestOrg : 'mytest',
        status_level : data.status_level || ''
      }
    }

    if(type == 'lms'){
      obj = {
        studentname : data.student.first_name,
        testName : data.chapter.name,
        course : '',
        certificate_id : data._id,
        updated : data.updated,
        signature : this.tenantSettingsData.settings.signature,
        certificate_type : '',
        requestOrg : 'lms',
        courseId : data.course._id,
        parentId : data.chapter.parent,
        examType : data.exam_type,
        chapterName : ''
      }
    }
    if(type == 'course'){
      obj = {
        studentname : data.student.first_name,
        course : data.course.fullName,
        certificate_id : data._id,
        updated : data.updated,
        signature : this.tenantSettingsData.settings.signature,
        certificate_type : 'Course Completion Certificate',
        requestOrg : 'course_completion',
        courseId : data.course._id,
        termName : '',
        parentId : '',
        examType : '',
        chapterName : ''
      }
    }

    localStorage.setItem('certificates',JSON.stringify(obj))
    this.router.navigate([]).then(result => {  window.open( `/opencertificate`, '_blank',); });
   }

}
