import { Component, OnInit, OnDestroy } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { APP_URLS } from '../../Models/url';
import { UserService } from '../../services/user.service';
import { MyProfileService } from '../../services/my-profile.service';
import { CommonService } from '../../services/common.service';
import { FilterPipe } from '../../Models/filter.pipe';

@Component({
  selector: 'app-employer-support-ticket',
  templateUrl: './employer-support-ticket.component.html',
  styleUrls: ['./employer-support-ticket.component.css'],
  providers:[FilterPipe]
})

export class EmployerSupportTicketComponent implements OnInit {
  showDialogTicket: boolean = false;
  p: Number = 1;
  term: any;
  countPagination: Number = 10;
  inbox: boolean = true
  compose: boolean = false
  replyTicketStaus: boolean = false;
  studentId: any;
  replypopupopen = 'none';
  ticket = []
  threadsData: any;
  fileSize: any;
  fileName: any;
  openCount = 0;
  selectedTicketData: any;
  count = 0;
  replydata = {
    message: ""
  }
  search: string;
  isTicketInfo : boolean = false;
  waitingFlag : boolean = false;
  isFlag :boolean = true;

  constructor(
    private profileSvc: MyProfileService,
    private userSvc: UserService,
    public cms: CommonService,
    private FilterSearch : FilterPipe,
    private toast: UserService) { }

  ngOnInit(): void {
    this.profileSvc.setSidemenuTab("support");
    this.profileSvc.getSupportCompose().subscribe(data => {
      this.ticket = [];
      this.ListTemp = [];
      if( this.isFlag ){
        this.isFlag = false;
        this.getSupportTicket()
      }
    })
    this.studentId = JSON.parse(localStorage.getItem('userDetails'))._id;
    this.InboxClick()
  }

  InboxClick() {
    this.inbox = true;
    this.compose = false;
    this.replyTicketStaus = false;
    this.getSupportTicket();
  }

  ComposeClick() {
    this.showDialogTicket = !this.showDialogTicket
    this.inbox = false;
    this.compose = true;
    this.replyTicketStaus = false;
  }

  List = [];
  ListTemp = [];
  onChangePage(pageOfItems: Array<any>) {
    // update current page of items
    this.List = pageOfItems;
  }

  onChangeEvent(event: any) {
    this.ListTemp = this.FilterSearch.transform(this.ticket,event,'');
    this.ListTemp.forEach((y, i) => {
      y['ticketindex'] = i + 1;
    })
    this.isTicketInfo = ( this.ListTemp.length ) ? false : true;
  }

  getSupportTicket() {

    //this.cms.loaderSet(true);
    this.waitingFlag = true;
    this.isTicketInfo = false;

    let url = APP_URLS.v1.supportTicket
    this.profileSvc.getCall(url)
      .subscribe((data: any) => {
        // this.cms.loaderSet(false);

        if (data) {
          this.ticket = []
          this.count = 0;
          if (data.list.length > 0) {
            data.list.forEach((x, i) => {
              x['sno']=i+1
              if (x.inbox_type == "") {
                
                this.ticket.push(x)
                this.ticket.sort((a, b) => {
                  return <any>new Date(b.created) - <any>new Date(a.created);
                });
                localStorage.setItem('ticketData', JSON.stringify(this.ticket))
                if (x.open_closed_thread == 'open') {
                  this.count = this.count + 1;
                  this.userSvc.setTicketCount(this.count)
                }
                if (this.count == 0) {
                  this.count = 0;
                  this.userSvc.setTicketCount(this.count)
                }
              }
            });
          }
          this.isFlag = true;

        }
        
        this.setIndex();
      },err=>{
        //this.cms.loaderSet(false);
      })

  }

  setIndex(){
    this.ticket.sort((a, b) => {
      return <any>new Date(b.created) - <any>new Date(a.created);
    });
    this.ticket.forEach((x, i) => {
      x['ticketindex'] = i + 1
    })
    this.ListTemp = JSON.parse(JSON.stringify(this.ticket));
    this.isTicketInfo = ( this.ListTemp.length ) ? false : true;
    this.waitingFlag = false;
  }


  replyTicket(data) {
    this.threadsData = data.threads;
    this.threadsData.forEach(x => {
      x['inboxId'] = data._id;
    });
    this.replyTicketStaus = true;
  }

  replyPopupOpen(data) {
    this.replypopupopen = 'block';
    this.replydata.message = "";
    this.fileName = "";
    this.selectedTicketData = data;
  }

  replyPopupClose() {
    this.replypopupopen = 'none'
  }
  
  handleFileInput(files) {
    this.fileSize = files[0].size
    const formData = new FormData();
    // this.fileToUpload = files.item(0);
    formData.append('images', files[0]);
    this.profileSvc.postCallImg(APP_URLS.supportTicketUpload, formData)
      .subscribe((data: any) => {
        if(data.errorMessage){
          this.toast.showError(data.errorMessage)
        } else {
          if( data.length )
            this.fileName = data[0].name;
          this.toast.showSuccess('Your chosen file is attached successfully. Now click on submit button')
        }
      }, err => {
        this.toast.showError(err.errorMessage)
      })
  }

  submitTicketReply() {
    let url = APP_URLS.v1.replySupportTicket
    
    let payload = {
      message : this.replydata.message,
      reply_to : this.selectedTicketData.uuid,
      ticket_id : this.selectedTicketData.inboxId,
      attachments: [
        {
          name: this.fileName,
          path: APP_URLS.imagePath + "download/"
        }
      ]

    }

    this.profileSvc.putCall(url, payload)
      .subscribe((data: any) => {
        this.replyPopupClose();
        this.InboxClick();
        this.toast.showSuccess(data.respMessage);
        this.replydata.message = "";
      }, err => {
        this.toast.showError(err.errorMessage)
      })
  }

  closeTicket(data) { 
    localStorage.removeItem('ticketData')
    let url = APP_URLS.tickentClose + data._id;
    let payload = { open_closed_thread: 'closed' }
    this.profileSvc.putCall(url, payload)
      .subscribe((data: any) => {
        this.getSupportTicket();
        this.toast.showSuccess(data.respMessage);
      }, err => {
        this.toast.showError(err.errorMessage)
      })
  }
}
  