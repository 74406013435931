import { Component, OnInit, Input } from '@angular/core';
import { MyProfileService } from '../../services/my-profile.service';
import { APP_URLS } from '../../Models/url';
import { CommonService } from '../../services/common.service';
import { Router } from '@angular/router';
import { Subscription } from 'rxjs';
@Component({
  selector: 'app-admission',
  templateUrl: './admission.component.html',
  styleUrls: ['./admission.component.css'],
})
export class AdmissionComponent implements OnInit {
  profilePermissions: any;
  tabId: any;
  tabHref: any;
  activeClass = '';
  QualactiveClass = '';
  EmpactiveClass = '';
  PeractiveClass = '';
  AddiactiveClass = '';
  FeeactiveClass = '';
  ChclstactiveClass = '';
  detailRecivedSubscription: Subscription;
  tabLoading: boolean = false;
  availableDetails: any;
  constructor(
    public service: MyProfileService,
    private router: Router,
    private cms: CommonService
  ) {
    this.service.setSidemenuTab('admission');
    this.detailRecivedSubscription = this.service.getTab().subscribe((data) => {
      if (data) {
        if (data.isRefreshPermission) {
          this.getProfilePermissions(data.tabName);
        } else {
          this.tabClick(data.tabName);
        }
      }
    });
  }

  ngOnInit(): void {
    this.tabLoading = true;
    this.getProfilePermissions('Personal Details');
  }

  ngOnDestroy() {
    this.detailRecivedSubscription.unsubscribe();
  }

  async getProfilePermissions(enableTab: any) {
    let url = APP_URLS.profilePermissions;
    await this.service.getCallWithHeaders(url).subscribe((res: any) => {
      this.profilePermissions = res.profile_permissions;
      this.availableDetails = res.admissionApplication;
      this.tabLoading = false;
      this.tabClick(enableTab);
    });
  }

  validateTabs(preKey) {
    var status;
    if (
      this.availableDetails &&
      this.availableDetails[preKey] &&
      this.availableDetails[preKey].tabAccess == false
    ) {
      status = 'disabled';
    } else {
      status = '';
    }
    return status;
  }

  tabClick(data) {
    this.activeClass = '';
    this.QualactiveClass = '';
    this.EmpactiveClass = this.validateTabs('employmentHistory');
    this.PeractiveClass = this.validateTabs('personalStatement');
    this.AddiactiveClass = this.validateTabs('additionalDocuments');
    this.FeeactiveClass = this.validateTabs('fee');
    this.ChclstactiveClass = this.validateTabs('checklistAndDeclarations');

    if (data == 'Personal Details') {
      this.activeClass = 'active';
      this.router.navigate(['/profile/admission/personalDetails']);
    }
    if (data == 'Qualification') {
      this.QualactiveClass = 'active';
      this.router.navigate(['/profile/admission/qualification']);
    }
    if (data == 'Employement') {
      this.EmpactiveClass = !this.validateTabs('employmentHistory')
        ? 'active'
        : this.validateTabs('employmentHistory');
      this.router.navigate(['/profile/admission/empHistory']);
    }
    if (data == 'Personal') {
      this.PeractiveClass = !this.validateTabs('personalStatement')
        ? 'active'
        : this.validateTabs('personalStatement');
      this.router.navigate(['/profile/admission/personalStatement']);
    }
    if (data == 'Additional') {
      this.AddiactiveClass = !this.validateTabs('additionalDocuments')
        ? 'active'
        : this.validateTabs('additionalDocuments');
      this.router.navigate(['/profile/admission/assitionalDoc']);
    }
    if (data == 'Fee') {
      this.FeeactiveClass = !this.validateTabs('fee')
        ? 'active'
        : this.validateTabs('fee');
      this.router.navigate(['/profile/admission/fee']);
    }
    if (data == 'Checklist') {
      this.ChclstactiveClass = !this.validateTabs('checklistAndDeclarations')
        ? 'active'
        : this.validateTabs('checklistAndDeclarations');
      this.router.navigate(['/profile/admission/checklist']);
    }
  }
}
