import {
  Component,
  OnInit,
  Input,
  HostListener,
  ViewChild,
} from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import {
  NgxFileDropEntry,
  FileSystemFileEntry,
  FileSystemDirectoryEntry,
} from 'ngx-file-drop';
import { APP_URLS } from 'src/app/Models/url';
import { CourseService } from 'src/app/services/course.service';
import { MyProfileService } from 'src/app/services/my-profile.service';
import { UserService } from 'src/app/services/user.service';
import { ActivatedRoute } from '@angular/router';
import { Subscription, forkJoin  } from 'rxjs';
import html2PDF from 'jspdf-html2canvas';
declare var $: any;

@Component({
  selector: 'app-accept-otj-job-model',
  templateUrl: './accept-otj-job-model.component.html',
  styleUrls: ['./accept-otj-job-model.component.css'],
})
export class AcceptOtjJobModelComponent implements OnInit {
  @ViewChild('sigPad') sigPad: { nativeElement: any };

  isSubmitted = false;
  files: NgxFileDropEntry[] = [];
  fileName = [];
  studentDetail: any = {};
  courseFullName = '';
  @Input() courseDetails: any;
  @Input() otjDetails: any;

  isEmpReviewLength = 0;
  isLearnSubmitLength = 0;
  isEmpCommentsLength = 0;

  studentId: any;
  signtureImage = APP_URLS.FILE_PATH_LINODE;
  sigPadElement: any;
  acceptStatement: false;
  acceptSignature: false;
  isSigned = false;
  context: any;
  isDrawing = false;
  img = '';
  Signature = '';
  role = '';
  displayName = '';
  routeRecords: Subscription;

  isEmployerCommentsLength = 0;
  isEmployerComments: Boolean = false;
  employerComments: any = {
    ratings: 0,
  };

  starRating: number[] = [1, 2, 3, 4, 5];

  constructor(
    private profileSvc: MyProfileService,
    private toast: UserService,
    private courseSvc: CourseService,
    private route: ActivatedRoute
  ) {
    this.role = localStorage.getItem('role');
    if (this.role == 'student') {
      var userDetails = JSON.parse(localStorage.getItem('userDetails'));
      this.studentId = userDetails._id;
      this.displayName = userDetails.displayName;
      var profileData = localStorage.getItem('profilesummaryData');
      if (profileData != undefined && typeof profileData == 'string') {
        this.studentDetail = JSON.parse(profileData);
        this.StudentSignature = this.signtureImage + 'signature/' + this.studentDetail?.Signature
      }
    }

    if (this.role == 'employer') {
      this.routeRecords = this.route.paramMap.subscribe((params) => {
        this.studentId = params.get('id');
        this.getProfileDetails();
      });
    }
  }
  employeeSign = '';
  employerSign = '';
  ngOnInit(): void {
    if (this.otjDetails?.employer_comments?.length > 0) {
      this.isEmployerCommentsLength =
        this.otjDetails?.employer_comments?.length;

      this.employerComments =
        this.otjDetails?.employer_comments[this.isEmployerCommentsLength - 1];
      
        if( this.employerComments?.employer?.signature ){
          this.employerSign = this.signtureImage + 'signature/' + this.employerComments?.employer?.signature;
        }
      this.isEmployerComments = true;
    } else {
      this.isEmployerComments = false;
      this.isEmpReviewLength = 0;
    }

    if (this.otjDetails?.employee_comments?.length > 0) {
      this.isEmployerComments = true;
      this.isEmpCommentsLength = this.otjDetails?.employee_comments?.length;
    }

    if (this.otjDetails?.employee_reviews?.length > 0) {
      this.isEmpReviewLength = this.otjDetails?.employee_reviews?.length;
    }

    if (this.otjDetails?.learner_submit?.length > 0) {
      this.isLearnSubmitLength = this.otjDetails?.learner_submit?.length;
    } else {
      this.isLearnSubmitLength = 0;
    }
    if (this.otjDetails?.final_employee_reviews?.employee?.signature ){
      this.employeeSign = this.signtureImage + 'signature/' + this.otjDetails?.final_employee_reviews?.employee?.signature;
    }
    this.courseFullName = localStorage.getItem('TrainingFullName');
    this.convertSignature();
  }

  StudentSignature = '';
  getProfileDetails() {
    let url = APP_URLS.profileDetails + this.studentId;
    this.profileSvc.getCallWithHeaders(url).subscribe(
      (res: any) => {
        this.studentDetail = res.details;
        this.displayName = this.studentDetail.displayName;
        this.StudentSignature = this.signtureImage + 'signature/' + this.studentDetail?.Signature
      },
      (err) => {}
    );
  }

  ngOnDestroy() {
    if (this.routeRecords) {
      this.routeRecords.unsubscribe();
    }
  }

  @HostListener('document:mouseup', ['$event'])
  onMouseUp(e) {
    this.isDrawing = false;
  }

  onMouseDown(e) {
    if (!this.context) {
      this.sigPadElement = this.sigPad.nativeElement;
      this.context = this.sigPadElement.getContext('2d');
      this.context.strokeStyle = '#3742fa';
    }
    this.isDrawing = true;
    this.isSigned = true;
    const coords = this.relativeCoords(e);
    this.context.moveTo(coords.x, coords.y);
  }

  onMouseMove(e) {
    if (!this.context) {
      this.sigPadElement = this.sigPad.nativeElement;
      this.context = this.sigPadElement.getContext('2d');
      this.context.strokeStyle = '#3742fa';
    }
    if (this.isDrawing) {
      const coords = this.relativeCoords(e);
      this.context.lineTo(coords.x, coords.y);
      this.context.stroke();
    }
  }

  private relativeCoords(event) {
    const bounds = event.target.getBoundingClientRect();
    const x = event.clientX - bounds.left;
    const y = event.clientY - bounds.top;
    return { x: x, y: y };
  }

  keyPress(event: any) {
    const pattern = /[0-9\+\:\ ]/;
    let inputChar = String.fromCharCode(event.charCode);
    if (event.keyCode != 8 && !pattern.test(inputChar)) {
      event.preventDefault();
    }
  }

  public dropped(files: NgxFileDropEntry[]) {
    this.files = files;
    for (const droppedFile of files) {
      // Is it a file?
      if (droppedFile.fileEntry.isFile) {
        const fileEntry = droppedFile.fileEntry as FileSystemFileEntry;
        fileEntry.file((file: File) => {
          this.handleFileInput(file);
        });
      } else {
        // It was a directory (empty directories are added, otherwise only files)
        const fileEntry = droppedFile.fileEntry as FileSystemDirectoryEntry;
        console.log(droppedFile.relativePath, fileEntry);
      }
    }
  }

  async handleFileInput(file) {
    const formData = new FormData();
    formData.append('images', file);
    await this.profileSvc.postCallImg(APP_URLS.fileUpload, formData).subscribe(
      (data: any) => {
        if (data.errorMessage) {
          this.toast.showError(data.errorMessage);
        } else {
          if (data.length) {
            this.fileName.push(data[0].name);
          }

          this.toast.showSuccess('Success');
        }
      },
      (err) => {
        this.toast.showError('Error');
      }
    );
  }

  public fileOver(event) {
    // console.log(event);
  }

  public fileLeave(event) {
    // console.log(event);
  }

  removeFile(file: NgxFileDropEntry) {
    this.files = this.files.filter(
      (data) => data.relativePath !== file.relativePath
    );

    this.fileName.forEach((fi) => {
      if (fi.substring(fi.lastIndexOf('-') + 1) == file.relativePath) {
        let name = fi.substring(fi.lastIndexOf('-') + 1);
        console.log(name, file.relativePath);
        this.fileName = this.fileName.filter(
          (data) => data.substring(fi.lastIndexOf('-') + 1) != file.relativePath
        );
      }
    });
  }

  captureScreenModel() {
    $('.pdf_download').css('height', 'auto');
    $('.pdf_download').css('overflow-y', 'auto');
    $('#downloadSectionModel').removeClass('d-none');

    setTimeout(() => {
      var data = document.getElementById('contentToConvertModel');
      html2PDF(data, {
        jsPDF: {
          unit: 'px',
          format: 'a4',
          orientation: 'portrait',
        },
        html2canvas: {
          scrollX: 0,
          scrollY: -window.scrollY,
          logging: false,
          useCORS: false,
        },
        imageType: 'image/jpeg',
        imageQuality: 1,
        margin: {
          top: 10,
          right: 5,
          bottom: 10,
          left: 5,
        },
        output: this.displayName + '_submitted_otj_record.pdf',
      });
      $('#downloadSectionModel').addClass('d-none');
      $('.pdf_download').css('height', '550px');
      $('.pdf_download').css('overflow-y', 'scroll');
    }, 100);
  }

  clear() {
    if (this.context) {
      this.context.clearRect(
        0,
        0,
        this.sigPadElement.width,
        this.sigPadElement.height
      );
      this.context.beginPath();
      this.isSigned = false;
    }
  }

  submitBtnDisabled: boolean = false;
  dosignature() {
    if (this.isSigned) {
      this.img = this.sigPadElement.toDataURL('image/png');
      this.submitBtnDisabled = true;
      if (
        this.img !=
        'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAASwAAABkCAYAAAA8AQ3AAAAC9ElEQVR4Xu3UAQkAAAwCwdm/9HI83BLIOdw5AgQIRAQWySkmAQIEzmB5AgIEMgIGK1OVoAQIGCw/QIBARsBgZaoSlAABg+UHCBDICBisTFWCEiBgsPwAAQIZAYOVqUpQAgQMlh8gQCAjYLAyVQlKgIDB8gMECGQEDFamKkEJEDBYfoAAgYyAwcpUJSgBAgbLDxAgkBEwWJmqBCVAwGD5AQIEMgIGK1OVoAQIGCw/QIBARsBgZaoSlAABg+UHCBDICBisTFWCEiBgsPwAAQIZAYOVqUpQAgQMlh8gQCAjYLAyVQlKgIDB8gMECGQEDFamKkEJEDBYfoAAgYyAwcpUJSgBAgbLDxAgkBEwWJmqBCVAwGD5AQIEMgIGK1OVoAQIGCw/QIBARsBgZaoSlAABg+UHCBDICBisTFWCEiBgsPwAAQIZAYOVqUpQAgQMlh8gQCAjYLAyVQlKgIDB8gMECGQEDFamKkEJEDBYfoAAgYyAwcpUJSgBAgbLDxAgkBEwWJmqBCVAwGD5AQIEMgIGK1OVoAQIGCw/QIBARsBgZaoSlAABg+UHCBDICBisTFWCEiBgsPwAAQIZAYOVqUpQAgQMlh8gQCAjYLAyVQlKgIDB8gMECGQEDFamKkEJEDBYfoAAgYyAwcpUJSgBAgbLDxAgkBEwWJmqBCVAwGD5AQIEMgIGK1OVoAQIGCw/QIBARsBgZaoSlAABg+UHCBDICBisTFWCEiBgsPwAAQIZAYOVqUpQAgQMlh8gQCAjYLAyVQlKgIDB8gMECGQEDFamKkEJEDBYfoAAgYyAwcpUJSgBAgbLDxAgkBEwWJmqBCVAwGD5AQIEMgIGK1OVoAQIGCw/QIBARsBgZaoSlAABg+UHCBDICBisTFWCEiBgsPwAAQIZAYOVqUpQAgQMlh8gQCAjYLAyVQlKgIDB8gMECGQEDFamKkEJEDBYfoAAgYyAwcpUJSgBAgbLDxAgkBEwWJmqBCVAwGD5AQIEMgIGK1OVoAQIPGZAAGU3BBs5AAAAAElFTkSuQmCC'
      ) {
        let url = APP_URLS.DoSignature;
        var Payload = {
          signature: this.img,
        };
        this.profileSvc.postCall(url, Payload).subscribe((res: any) => {
          if (res.respCode) {
            if (res.details) {
              this.Signature = res.details.siganture;
              this.toast.showSuccess('Your signature is saved successfully');
            }
            this.update();
          }
          if (res.errorCode) {
            this.toast.showError(res.errorMessage);
          }
        });
      } else {
        this.toast.showError('Please draw your signature');
        this.submitBtnDisabled = false;
      }
    }
  }

  async update() {
    let url = APP_URLS.profileDetails + this.studentId;
    let Payload = {
      Signature: this.Signature,
    };

    await this.profileSvc.putCall(url, Payload).subscribe((res: any) => {
      if (res.respCode) {
        this.studentDetail.Signature = this.Signature;
        localStorage.setItem('profilesummaryData', '');
        localStorage.setItem(
          'profilesummaryData',
          JSON.stringify(this.studentDetail)
        );
        this.submitBtnDisabled = false;
      }
      if (res.errorCode) {
        this.toast.showError(res.errorMessage);
        this.submitBtnDisabled = false;
      }
    });
  }

  onSubmit() {
    if (!this.otjDetails.acceptStudentDeclaration) {
      this.isSubmitted = true;

      if (!this.acceptStatement) {
        this.toast.showError('Please accept declaration');
        return;
      }

      if (!this.acceptSignature) {
        this.toast.showError('Please accept signature');
        return;
      }

      if (!this.studentDetail.Signature) {
        this.toast.showError('Signature Required');
        return;
      }

      let url = APP_URLS.postOffTheJobs;

      var payload = {
        updateid: this.otjDetails._id,
        // record_id : this.otjDetails?.learner_submit[0]?._id || '',
        acceptStudentDeclaration: this.acceptStatement,
        acceptStudentSignature: this.acceptSignature,
      };

      this.courseSvc.postCallwithHeaders(url, payload).subscribe(
        (data: any) => {
          this.isSubmitted = false;
          this.toast.showSuccess('Success');
          this.closeModal();
        },
        (err) => {
          this.isSubmitted = false;
          this.closeModal();
          this.toast.showError('Error');
        }
      );
    }
  }

  enableDownloadBtn : boolean = true;
  convertSignature(): any {
    this.enableDownloadBtn = true;
    let url = APP_URLS.v1.getEncodedUrl;

    var stuSign = this.courseSvc
      .postCallwithHeaders(url, {
        path: this.StudentSignature,
      });

    var employeeSign = this.courseSvc
      .postCallwithHeaders(url, {
        path: this.employeeSign,
      });

    var employerSign = this.courseSvc
      .postCallwithHeaders(url, {
        path: this.employerSign,
      });
      
      forkJoin([stuSign, employeeSign, employerSign]).subscribe((results : any) => {
        this.enableDownloadBtn = false;
        if( this.StudentSignature && results[0] ){
          this.StudentSignature = 'data:image/jpeg;base64,' + results[0]?.encodeData;
        }
        if( this.employeeSign && results[1] ){
          this.employeeSign = 'data:image/jpeg;base64,' + results[1]?.encodeData;
        }
        if( this.employerSign && results[2] ){
          this.employerSign = 'data:image/jpeg;base64,' + results[2]?.encodeData;
        }
      });
  }

  closeModal() {
    this.profileSvc.closeModalPopup();
  }

  getNumber(num) {
    return new Array(num);
  }
}
