import { Component, OnInit, OnDestroy } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { APP_URLS } from '../../Models/url';
import { CourseService } from '../../services/course.service';
import { MyProfileService } from '../../services/my-profile.service';
import { UserService } from '../../services/user.service';
import { ToastrService } from 'ngx-toastr';
import { Subscription } from 'rxjs';
import { TenantId } from '../../Models/url';
declare let $: any;
@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.css'],
})
export class HeaderComponent implements OnInit {
  showDialog = false;
  showDialogForgot = false;
  showDialogReg = false;

  sub: any;
  page: number;
  headerClass = '';
  selectCourseId: any;
  selectCourseIdData: any;
  token: boolean = false;

  CorseData = [];

  background_image = '';
  userDetail: any;
  CartCount = 0;
  CartList = [];
  student_Id = '';
  detailRecivedSubscription: Subscription;
  permissionData: any;
  myAtLaBShow: string = '';
  myOTJShow: string = '';


  constructor(
    private router: Router,
    private route: ActivatedRoute,
    public service: CourseService,
    private userSvc: UserService,
    private profileSvc: MyProfileService,
    private toastr: ToastrService
  ) {
    this.background_image = '../../../assets/images/banner.png';
    if (localStorage.getItem('token')) {
      this.getProfilePermissions();
    }
    this.detailRecivedSubscription = this.userSvc
      .getCart()
      .subscribe((data) => {
        if (data) {
          // let dataofcart = localStorage.getItem('cartdata')
          // if (dataofcart != null) {
          //   this.CartList = dataofcart.split(',')
          //   // this.addtocartCall();
          // }
          // this.getCart()
          this.ngOnInit();
        }
      });
  }

  ngOnDestroy() {
    this.detailRecivedSubscription.unsubscribe();
  }

  userRole = '';
  ngOnInit(): void {
    this.userRole = localStorage.getItem('role');

    var profileData = localStorage.getItem('profilesummaryData');
    if (profileData != undefined && typeof profileData == 'string') {
      this.userDetail = JSON.parse(profileData);
      if (this.userDetail != null) {
        this.student_Id = this.userDetail._id;
      }
    }

    if (this.userRole != 'employer') {
      let dataofcart = localStorage.getItem('cartdata');
      this.CorseData = [];
      this.CartList = [];
      this.CartCount = 0;
      if (dataofcart != null) {
        this.CartList = dataofcart.split(',');
        this.CartCount = this.CartList.length;
        // this.addtocartCall();
      }
    }

    this.profileSvc.getForgotPop().subscribe((data: any) => {
      if (data == 'forgot') {
        this.showDialog = false;
        this.showDialogForgot = true;
        this.showDialogReg = false;
      }
      if (data == 'register') {
        this.showDialog = false;
        this.showDialogForgot = false;
        this.showDialogReg = true;
      }
      if (data == 'login') {
        this.showDialog = true;
        this.showDialogForgot = false;
        this.showDialogReg = false;
      }
    });
    this.profileSvc.getPopup().subscribe((data: any) => {
      this.showDialogReg = false;
    });

    this.userSvc.getIsLogin().subscribe((data) => {
      this.token = data;
    });

    if (localStorage.getItem('token') && this.student_Id) {
      this.token = true;
    }

    this.selectCourseId = this.service
      .getDataToHeader()
      .subscribe((data: any) => {
        this.selectCourseIdData = data.text;
      });

    this.sub = this.route.queryParams.subscribe((params) => {
      this.page = +params['page'] || 0;
      if (this.page == 0) {
        this.headerClass = 'header';
      } else if (this.page == 1) {
        this.headerClass = 'header inner-ban bg-imag-abt';
      } else if (this.page == 2) {
        this.headerClass = 'header inner-ban';
      } else if (this.page == 3) {
        this.headerClass = 'header inner-ban';
      } else if (this.page == 4) {
        this.headerClass = 'header inner-ban';
      } else if (this.page == 5) {
        this.headerClass = 'header inner-ban';
      }
      $(function () {
        $(window).on('scroll', function () {
          if ($(window).scrollTop() > 1) {
            $('.top-header').addClass('top-fix');
          } else {
            $('.top-header').removeClass('top-fix');
          }
        });
      });
      $(function () {
        $(window).on('scroll', function () {
          if ($(window).scrollTop() > 1) {
            $('.banner-carousel').addClass('padding-set-scroll');
          } else {
            $('.banner-carousel').removeClass('padding-set-scroll');
          }
        });
      });
    });
    if (this.userRole != 'employer') {
      this.getCourseList();
      this.getCart();
    }
  }

  logout() {
    localStorage.clear();
    this.userSvc.setIsLogin(false);
    this.router.navigate(['/home/'], { queryParams: { page: 0 } });
    // setTimeout(function(){
    //   location.reload(); }, 1500);
  }

  getCourseList() {
    this.CorseData = [];
    let url = APP_URLS.latestcourse;
    this.service.getCall(url).subscribe((res: any) => {
      var name = [];
      var id = '';
      var categories = Object.values(res.course_category_detail);
      categories.forEach((x: any) => {
        var obj = {
          course_type: '',
          description: '',
          courses: [],
        };
        const repetingdata = name.find(
          (z) => z == res.course_category_detail.course_type
        );
        if (repetingdata) {
        } else {
          obj.course_type = x.title;
          obj.description = x.description;
          if (x.course) {
            x.course.forEach((y) => {
              if (x.title == y.course_type) {
                obj.courses.push({
                  id: y._id,
                  name: y.name,
                  slug: y.slug,
                });
              }
            });
            this.CorseData.push(obj);
          }
        }
      });
    });
  }

  openCourses(coursetype) {
    localStorage.setItem('courseType', coursetype.course_type);
    this.router.navigate([
      '/Courses/' + coursetype.course_type.replace(/\s/g, '-'),
    ]);
  }

  openIndividulaCourse(course) {
    localStorage.setItem('Individualcourse', course.slug);
    //  let name = course.slug.split(" "+"(");
    this.router.navigate(['/' + course.slug]);
  }

  profile() {
    if (this.userRole == 'employer') {
      this.router.navigate(['/employer-profile/emp-profile-summary']);
    } else {
      this.router.navigate(['/profile']);
    }
    this.service.setProfile('message');
  }

  mywork() {
    if (this.userRole == 'employer') {
      this.router.navigate(['/employer-mywork/emp-my-project']);
    } else {
      this.router.navigate(['/student-my-work/student-work-summary']);
    }
  }

  home() {
    this.router.navigate(['/home']);
    // setTimeout(() => { location.reload(); }, 300);
  }

  openCart() {
    this.router.navigate(['/Cart']);
  }

  getCart() {
    if (localStorage.getItem('token') && this.student_Id) {
      let url = APP_URLS.getcart;
      let payload = {};
      this.userSvc.postCallwithHeaders(url, payload).subscribe((res: any) => {
        this.CartCount = res.listCount;
      });
    } else {
      let dataofcart = localStorage.getItem('cartdata');
      this.CartCount = 0;
      if (dataofcart != null) {
        this.CartList = dataofcart.split(',');
        this.CartCount = this.CartList.length;
        // this.addtocartCall();
      }
    }
    // else {
    //   let url = APP_URLS.getcart;
    //   let payload = {
    //     courseids: this.CartList,
    //     tenantId: TenantId
    //   }
    //   this.userSvc.postCall(url, payload).subscribe((res: any) => {

    //     this.CartCount = res.listCount;

    //   })

    //   // let url = APP_URLS.getcart;
    //   // let payload={
    //   //   courseids : this.CartList,
    //   //   tenantId : TenantId
    //   // }
    //   // this.userSvc.postCall(url,payload).subscribe((res:any)=>{
    //   //   this.CartCount=res.listCount

    //   // })

    // }
  }
  
  async getProfilePermissions() {
    let url = APP_URLS.profilePermissions;

    await this.profileSvc.getCallWithHeaders(url).subscribe(
      (res: any) => {
        this.myAtLaBShow = res?.my_at_lab;
        this.myOTJShow = res?.my_otj;
      },
      (err) => {
        console.log(err);
      }
    );
  }
}
