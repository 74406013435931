import { Component, OnInit } from '@angular/core';
import { APP_URLS } from '../../Models/url';
import { TenantId } from '../../Models/url';
import { TrainingService } from '../../services/training.service';
import { Router } from '@angular/router';
import { CommonService } from '../../services/common.service'
import { CourseService } from '../../services/course.service';
import { from } from 'rxjs';
import { UserService } from 'src/app/services/user.service';
import { MyProfileService } from 'src/app/services/my-profile.service';

@Component({
  selector: 'app-mytraining',
  templateUrl: './mytraining.component.html',
  styleUrls: ['./mytraining.component.css']
})
export class MytrainingComponent implements OnInit {
  TrainingList = []
  courseid = "";
  buttonType = ""
  FinalChaptersArray = []
  Chapters = []
  ID: any;
  constructor(public service: TrainingService,
    public courcecms: CourseService,
    private cms: CommonService,
    private userSvc: UserService,
    public myProSvc: MyProfileService,
    private router: Router) { }
  allTranings: boolean = true
  applaidTrainings: boolean = false
  favourateTrainings: boolean = false
  StartCourse: boolean = false;
  isnoDataFound: boolean = false;
  noDataMsg:string;
  showType:string = 'all';
  async ngOnInit() {
    await this.ShowAllTrainings()
    this.courseid = this.courcecms.courceid;
    this.buttonType = this.courcecms.buttonType;

    if (this.courcecms.buttonType != "" && this.courcecms.buttonType == "apply") {
      this.apply(this.courcecms.courceid)
    }
    if (this.courcecms.buttonType != "" && this.courcecms.buttonType == "addtocart") {
      this.addToCart(this.courcecms.courceid)
    }

  }
  apply(data) {
    let url = APP_URLS.applyAndFavourate + data + '?applycourse=true';

    this.service.putCall(url, {})
      .subscribe((data: any) => {
        this.userSvc.showSuccess(data.respMessage);
        this.courcecms.selectedcourse("", "");
        this.getTrainingList()
      }, err => {

      })
  }

  alreadyApplied(){
    this.courcecms.showSuccess('You have already applied for this course and waiting for Admin approval. For further information, you may contact your Admin');
  }
  waitingForApproval(){
    this.courcecms.showError("Waiting for Admin approval. Contact your Admin for further information")
  }
  addFavorateFun(data) {

    let url = APP_URLS.applyAndFavourate + data + '?favorite=true';

    this.service.putCall(url, {})
      .subscribe((data: any) => {
        this.userSvc.showSuccess(data.respMessage);

        this.courcecms.selectedcourse("", "");
        this.getTrainingList()
      }, err => {

      })
  }

  async getTrainingList() {
    this.cms.loaderSet(true)
    let url = APP_URLS.allTrainings;
    await this.service.getCallWithHeaders(url).subscribe((res: any) => {
      this.TrainingList = []
      var targetArr = res[this.showType];
      for (let i = targetArr.length - 1; i >= 0; i--) {
        this.TrainingList.push(targetArr[i])
      }
      this.TrainingList.sort((a, b) => {
        return <any>new Date(b.created) - <any>new Date(a.created);
      });
      if( ! this.TrainingList.length ){
        this.isnoDataFound = true;
        if(this.showType=='all'){
          this.noDataMsg = 'No course(s) found';
        } else if(this.showType=='applied'){
          this.noDataMsg = 'No course(s) are applied';
        } else if(this.showType=='assigned'){
          this.noDataMsg = 'No course(s) are approved';
        } else if(this.showType=='favorites'){
          this.noDataMsg = 'No favourite course(s) found';
        }
      }
      if (this.courcecms.courceid != "" && this.courcecms.buttonType == "favourite") {
        if (this.TrainingList.length > 0) {
          this.TrainingList.forEach(x => {
            if (this.courcecms.courceid == x._id) {
              if (x.fav_status == false) {
                this.addFavorateFun(this.courcecms.courceid)
              }
            }
          })
        }
      }
      this.cms.loaderSet(false)
    })
  }
  
  async ShowAllTrainings() {
    this.isnoDataFound = false;
    this.allTranings = true;
    this.applaidTrainings = false;
    this.favourateTrainings = false;
    this.showType = "all";
    await this.getTrainingList()
  }
  AppliedTrainings() {
    this.isnoDataFound = false;
    this.allTranings = false;
    this.applaidTrainings = true;
    this.favourateTrainings = false;
    this.StartCourse = true;
    this.showType = "applied";
    this.getTrainingList()
  }
  ApprovedTrainings() {
    this.isnoDataFound = false;
    this.allTranings = false;
    this.applaidTrainings = false;
    this.favourateTrainings = false;
    this.StartCourse = true;
    this.showType = "assigned";
    this.getTrainingList()
  }
  FavouriteTrainings() {
    this.isnoDataFound = false;
    this.allTranings = false;
    this.applaidTrainings = false;
    this.favourateTrainings = true;
    this.showType = "favorites";
    this.getTrainingList()
  }
  openCourse(Training) {
    localStorage.setItem('courseId', Training._id)
    localStorage.setItem('TrainingName', Training.name)
    localStorage.setItem('TrainingFullName', Training.fullName);
    localStorage.setItem('uuid', Training.resume_chapter)
    if (Training.apply_status == "assigned" && Training.resume_chapter == '') {
      this.router.navigate(['/Mycourses/', Training._id])
    }
    if (Training.apply_status == "assigned" && Training.resume_chapter != '') {

      this.ID = localStorage.getItem('courseId')
      let url = APP_URLS.newchapters + '{"criteria":[{"key":"tenantId","value":"' + TenantId + '","type":"eq"},{"key":"course","value":"' + this.ID + '","type":"eq"}]}'
      this.service.getCallWithHeaders(url).subscribe(
        (res: any) => {
          this.Chapters = res.cou_overview;
          this.setChaptersData();
        },
        err => {

        })

    }
  }
  CourseNameClick(Training) {
    localStorage.setItem('courseId', Training._id)
    localStorage.setItem('TrainingName', Training.name)
    localStorage.setItem('TrainingFullName', Training.fullName);
    localStorage.setItem('uuid', Training.resume_chapter)
    if (Training.apply_status == "assigned") {
      this.router.navigate(['/Mycourses/', Training._id])
    }
    else {
      this.courcecms.showError("Add As Favorite or Apply")
    }
  }

  async getData() {
    this.ID = localStorage.getItem('courseId')
    let url = APP_URLS.newchapters + '{"criteria":[{"key":"tenantId","value":"' + TenantId + '","type":"eq"},{"key":"course","value":"' + this.ID + '","type":"eq"}]}'
    await this.service.getCallWithHeaders(url).subscribe(
      (res: any) => {
        this.Chapters = res.cou_overview;
        this.setChaptersData();
      },
      err => {

      })
  }
  async setChaptersData() {
    await this.Chapters.forEach((x, i) => {
      x['accordion'] = '#accordion' + i
      x['accordionID'] = 'accordion' + i
      x.child.forEach((y, index) => {
        y['childaccordion'] = '#childaccordion' + index
        y['childaccordionID'] = 'childaccordion' + index
        if (y.chapter_type == "lesson") {
          y['icon'] = "fa fa-book"
        }
        if (y.chapter_type == "assignment") {
          y['icon'] = "fa fa-code"
        }
        if (y.chapter_type == "downloads") {
          y['icon'] = "fa fa-cloud-download-alt"
        }
        if (y.chapter_type == "test" || y.chapter_type == "qatest") {
          y['icon'] = "fa fa-pencil-alt"
        }
        if (y.chapter_type == "quiz") {
          y['icon'] = "fa fa-graduation-cap"
        }
        if (y.chapter_type == "chapter") {
          y['icon'] = "fa fa-bars"
        }
      })
      this.FinalChaptersArray.push(x)
      localStorage.setItem('AllChapters', JSON.stringify(this.FinalChaptersArray))
      var Trainingid = localStorage.getItem('courseId')
      var TrainingName = localStorage.getItem('TrainingName')
      var resume_chapter = localStorage.getItem('uuid')
      this.router.navigate(['/Mychapter/', Trainingid + "-" + resume_chapter, TrainingName.replace(/\s/g, '-')])
    })
  }


  addToCart(id) {

    let url = APP_URLS.addtocart + id + '?addtocart=true';

    this.service.putCall(url, {})
      .subscribe((data: any) => {
        // if (data.respCode) { 
        // this.courcecms.showSuccess(data.respMessage);
        if (data.suc_arr.length > 0) {
          this.userSvc.showSuccess(data.suc_arr[0]);
          this.userSvc.setCart('data')
        }
        if (data.err_arr.length > 0) {
          this.userSvc.showError(data.err_arr[0]);
        }
        this.userSvc.setCart("cart")
        this.getTrainingList();
        // }else{
        //   this.courcecms.showError(data.err_arr[0]);
        // }

      }, err => {

      })

  }


}
