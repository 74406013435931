import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { APP_URLS } from 'src/app/Models/url';
import { MyProfileService } from 'src/app/services/my-profile.service';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-otj-panel',
  templateUrl: './otj-panel.component.html',
  styleUrls: ['./otj-panel.component.css'],
})
export class OtjPanelComponent implements OnInit {
  tabPanel = 'activity';
  studentId = '';
  selectStudentName = '';
  studentList: any[] = [];
  role = '';
  routeRecords: Subscription;

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    public service: MyProfileService
  ) {
    this.routeRecords = this.route.paramMap.subscribe((params) => {
      this.studentId = params.get('id');
      if (this.studentId != null) this.role = 'employer';
    });
  }

  ngOnInit(): void {
    if (this.studentId != null) {
      this.getApprenticeDetails();
    }
  }

  ngOnDestroy() {
    if (this.routeRecords) {
      this.routeRecords.unsubscribe();
    }
  }

  toggleTab(val: string): void {
    this.tabPanel = val;
  }

  backToEmpOtj(): void {
    this.studentId = '';
    this.router.navigate(['employer-myotj']);
  }

  enableTab: boolean = true;
  onChangeFun(e): void {
    // this.enableTab = false;
    this.studentId = e.target.value;
    this.studentList.forEach((data) => {
      if (data?.students?._id == this.studentId) {
        this.selectStudentName = data?.students?.displayName;
      }
    });
    this.router.navigate([`employer-myotj/` + this.studentId]);
    // this.toggleTab(this.tabPanel);
    // setTimeout(()=>{
    //   this.enableTab = true;
    //   }, 1000);
  }

  getApprenticeDetails() {
    let url = APP_URLS.v1.getEmployeeUserList + '?target=otj';
    this.service.getCallWithHeaders(url).subscribe((res: any) => {
      if (res?.myUserList) {
        this.studentList = res.myUserList ? res.myUserList : [];
        this.studentList.forEach((data) => {
          if (data?.students?._id == this.studentId) {
            this.selectStudentName = data?.students?.displayName;
          }
        });
      }
    });
  }
}
