import { Component, OnInit } from '@angular/core';
import { APP_URLS, tenantInfo } from 'src/app/Models/url';
import { UserService } from '../../services/user.service';
import { ToastrService } from 'ngx-toastr';
import { CommonService } from '../../services/common.service';
import { FormBuilder, FormGroup, Validators, FormControl } from '@angular/forms';
import { TenantId } from '../../Models/url';

@Component({
  selector: 'app-contact',
  templateUrl: './contact.component.html',
  styleUrls: ['./contact.component.css']
})
export class ContactComponent implements OnInit {
  tenantInfo: any = tenantInfo;
  contact = {
    email: '',
    name: '',
    subject: '',
    message: ''
  }
  userRole:any;
  constructor(
    private userSvc: UserService,
    private toastr: ToastrService,
    private cms: CommonService,) { }

  ngOnInit(): void {
    this.userRole = localStorage.getItem('role');
  }

  contactus() {

    let url = APP_URLS.contactus
    let payload = {
      email: this.contact.email,
      first_name: this.contact.name,
      message: this.contact.message,
      subject: this.contact.subject,
      tenantId: TenantId,
      from_place: 'contact' // static
    }

    this.userSvc.postCall(url, payload).subscribe(
      (res: any) => {
        if (res.respCode) {
          // this.cms.loaderSet(false);
          this.userSvc.showSuccess(res.respMessage);


        }
        else {
          // this.cms.loaderSet(false);
          this.userSvc.showError(res.respMessage)
        }
      },

      (error) => {

        // this.cms.loaderSet(false);
        this.userSvc.showError('contact Failed')
      })

  }

}
