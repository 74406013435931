import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { FormsModule } from '@angular/forms';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { HttpClientInMemoryWebApiModule } from 'angular-in-memory-web-api';
import { InMemoryDataService } from './in-memory-data.service';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { ReactiveFormsModule } from '@angular/forms';
import { MessagesComponent } from './messages/messages.component';
import { PLATFORM_ID, APP_ID, Inject } from '@angular/core';
import { CommonModule, isPlatformBrowser } from '@angular/common';
import { HeaderComponent } from './components/header/header.component';
import { FooterComponent } from './components/footer/footer.component';
import { HomeComponent } from './pages/home/home.component';
import { AboutusComponent } from './pages/aboutus/aboutus.component';
import { BlogComponent } from './pages/blog/blog.component';
import { EventsComponent } from './pages/events/events.component';
import { TrainingsComponent } from './pages/trainings/trainings.component';
import { AboutcarouselComponent } from './components/aboutcarousel/aboutcarousel.component';
import { HomecarouselComponent } from './components/homecarousel/homecarousel.component';
import { BlogcarouselComponent } from './components/blogcarousel/blogcarousel.component';
import { TrainingcarouselComponent } from './components/trainingcarousel/trainingcarousel.component';
import { EventscarouselComponent } from './components/eventscarousel/eventscarousel.component';
import { CertificatecarouselComponent } from './components/certificatecarousel/certificatecarousel.component';
import { HomeCompaniesComponent } from './components/home-companies/home-companies.component';
import { HomeBusinessComponent } from './components/home-business/home-business.component';
import { HomeCategoriesComponent } from './components/home-categories/home-categories.component';
import { HomeStudentsComponent } from './components/home-students/home-students.component';
import { HomeCyberSecurityComponent } from './components/home-cyber-security/home-cyber-security.component';
import { HomeProfessionalCoursesComponent } from './components/home-professional-courses/home-professional-courses.component';
import { HomeCourseCardComponent } from './components/home-course-card/home-course-card.component';
import { HomeContentCardsComponent } from './components/home-content-cards/home-content-cards.component';
import { ContactComponent } from '../app/pages/contact/contact.component';
import { ContactLocationComponent } from '../app/components/contact-location/contact-location.component';
import { ContactRegisterComponent } from '../app/components/contact-register/contact-register.component';
import { SupportComponent } from './components/support/support.component';
import { OwlModule } from 'ngx-owl-carousel';
import { CarouselModule } from 'ngx-owl-carousel-o';
import { NgxFileDropModule } from 'ngx-file-drop';
import { NgxMaterialTimepickerModule } from 'ngx-material-timepicker';

import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

import { ToastrModule } from 'ngx-toastr';
import { SelectcourseComponent } from './pages/selectcourse/selectcourse.component';
import { SelectcoursecarouselComponent } from './components/selectcoursecarousel/selectcoursecarousel.component';
import { NgxPaginationModule } from 'ngx-pagination';
import { PDFExportModule } from '@progress/kendo-angular-pdf-export';

import { UserService } from './services/user.service';
import { CourseService } from '../app/services/course.service';
import { ModaldialogComponent } from './pages/modaldialog/modaldialog.component';
import { LoginComponent } from './components/login/login.component';
import { RegisterComponent } from './components/register/register.component';
import { DashboardComponent } from './pages/dashboard/dashboard.component';
import { Header2Component } from './components/header2/header2.component';
import { ProfileComponent } from './pages/profile/profile.component';
import { MytrainingComponent } from './pages/mytraining/mytraining.component';
import { AppliedTrainingsComponent } from './components/applied-trainings/applied-trainings.component';
import { FavouriteTrainingsComponent } from './components/favourite-trainings/favourite-trainings.component';
import { AllTrainingsComponent } from './components/all-trainings/all-trainings.component';
import { CoursesComponent } from './pages/courses/courses.component';
import { CreateCertificatesComponent } from './pages/create-certificates/create-certificates.component';
import { MyCertificatesComponent } from './pages/my-certificates/my-certificates.component';
import { IndividualCourseComponent } from './pages/individual-course/individual-course.component';
import { MycoursesComponent } from './pages/mycourses/mycourses.component';
import { AdmissionComponent } from './components/admission/admission.component';
import { BookInterviewComponent } from './components/book-interview/book-interview.component';
import { ProfileSummaryComponent } from './components/profile-summary/profile-summary.component';
import { EditProfileComponent } from './components/edit-profile/edit-profile.component';
import { EmployeeHistoryComponent } from './components/employee-history/employee-history.component';
import { PersonalStatementComponent } from './components/personal-statement/personal-statement.component';
import { AdditionalDocumentsComponent } from './components/additional-documents/additional-documents.component';
import { FeeComponent } from './components/fee/fee.component';
import { ChecklistDocumentsComponent } from './components/checklist-documents/checklist-documents.component';
import { QualificationsComponent } from './components/qualifications/qualifications.component';
import { ProgressionComponent } from './components/progression/progression.component';
import { SupportTicketsComponent } from './components/support-tickets/support-tickets.component';
import { ComplaintComponent } from './components/complaint/complaint.component';
import { FeedbacksComponent } from './components/feedbacks/feedbacks.component';
import { AddQualificationModalComponent } from './components/modal-components/add-qualification-modal/add-qualification-modal.component';
import { AddEmployementHistoryModalComponent } from './components/modal-components/add-employement-history-modal/add-employement-history-modal.component';
import { AddPersonalStatementModalComponent } from './components/modal-components/add-personal-statement-modal/add-personal-statement-modal.component';
import { AddAdditionalDocumentModalComponent } from './components/modal-components/add-additional-document-modal/add-additional-document-modal.component';
import { CertificatesTabComponent } from './components/certificates-tab/certificates-tab.component';
import { ComplaintComposeModalComponent } from './components/modal-components/complaint-compose-modal/complaint-compose-modal.component';

import { SupportTickectComposeComponent } from './components/modal-components/support-tickect-compose/support-tickect-compose.component';

import { OffthejobsComponent } from './components/offthejobs/offthejobs.component';
import { AddOffthejobModalComponent } from './components/modal-components/add-offthejob-modal/add-offthejob-modal.component';
import { EditOffthejobModalComponent } from './components/modal-components/edit-offthejob-modal/edit-offthejob-modal.component';
import { EvidenceSampleModelComponent } from './components/modal-components/evidence-sample-model/evidence-sample-model.component';
import { PortfolioSubmitModelComponent } from './components/modal-components/portfolio-submit-model/portfolio-submit-model.component';
import { PortfolioComponent } from './components/portfolio/portfolio.component';
import { CourseDownloadComponent } from './components/course-download/course-download.component';
import { PortfolioEvidenceSubmitComponent } from './components/portfolio-evidence-submit/portfolio-evidence-submit.component';
import { PlayComponent } from './components/play/play.component';
import { AboutcourseComponent } from './components/aboutcourse/aboutcourse.component';
import { TestComponent } from './components/test/test.component';
import { QatestComponent } from './components/qatest/qatest.component';
import { QuizComponent } from './components/quiz/quiz.component';
import { SelectCertificateComponent } from './components/select-certificate/select-certificate.component';
import { CmsComponent } from './components/cms/cms.component';
import { ForgotpasswordComponent } from './components/forgotpassword/forgotpassword.component';
import { SetpasswordComponent } from './components/setpassword/setpassword.component';
import { CountdownModule } from 'ngx-countdown';

import { FeedbackComponent } from './components/modal-components/feedback/feedback.component';
import { NotificationsComponent } from './components/notifications/notifications.component';
import { PrivacypolicyComponent } from './components/privacypolicy/privacypolicy.component';
import { CookiepolicyComponent } from './components/cookiepolicy/cookiepolicy.component';
import { LmsInnerComponent } from './components/lms-inner/lms-inner.component';
import { LessonCompleteModelComponent } from './components/modal-components/lesson-complete-model/lesson-complete-model.component';
import { LmsInnerAssignmentComponent } from './components/lms-inner-assignment/lms-inner-assignment.component';
import { LmsInnerExamtypeComponent } from './components/lms-inner-examtype/lms-inner-examtype.component';
import { ActionCenterComponent } from './components/action-center/action-center.component';
import { CourseOverviewStandardsComponent } from './components/modal-components/course-overview-standards/course-overview-standards.component';
import { CourseFeedbackModelComponent } from './components/modal-components/course-feedback-model/course-feedback-model.component';

import { AlphabetOnlyDirective } from '../app/Models/alphabet-only.directive';
import { ComplaintOpenComponent } from './components/modal-components/complaint-open/complaint-open.component';
import { UpdatePictureComponent } from './components/modal-components/update-picture/update-picture.component';

import { ExcelService } from './services/excel.service';
import { FilterPipe } from './Models/filter.pipe';
import { TimeconverterPipe } from './Models/displaytimeformat-converter.pipe';
import { DemoComponent } from './components/demo/demo.component';

import { CookieLawModule } from 'angular2-cookie-law';

import { FlatpickrModule } from 'angularx-flatpickr';
import { CalendarModule, DateAdapter } from 'angular-calendar';
import { adapterFactory } from 'angular-calendar/date-adapters/date-fns';

import { LmsInnerSidenavComponent } from './components/lms-inner-sidenav/lms-inner-sidenav.component';
import { OpencertificateComponent } from './pages/opencertificate/opencertificate.component';
import { OnlineStatusComponent } from './components/online-status/online-status.component';
import { BookInterviewNewComponent } from './components/book-interview-new/book-interview-new.component';
import { BookInterviewTabsComponent } from './components/book-interview-tabs/book-interview-tabs.component';
import { CourseExamComponent } from './components/course-exam/course-exam.component';
import { OfferLetterComponent } from './components/offer-letter/offer-letter.component';
import { ViewAllCoursesComponent } from './components/view-all-courses/view-all-courses.component';
import { CartDetailsComponent } from './components/cart-details/cart-details.component';
import { CheckoutComponent } from './components/checkout/checkout.component';
import { NgxPayPalModule } from 'ngx-paypal';
import { JwPaginationModule } from 'jw-angular-pagination';
import { HelpDeskComponent } from './components/help-desk/help-desk.component';
import { ViewTestReportSummaryComponent } from './components/modal-components/view-test-report-summary/view-test-report-summary.component';
import { LmsInnerCertificateComponent } from './components/lms-inner-certificate/lms-inner-certificate.component';

import { BackButtonDisableModule } from 'angular-disable-browser-back-button';
import { ErrorPageComponent } from './components/error-page/error-page.component';
import { StudywithusComponent } from './components/studywithus/studywithus.component';
import { BlendedlearningComponent } from './components/blendedlearning/blendedlearning.component';
import { TeachersComponent } from './components/teachers/teachers.component';
import { HowtoapplyComponent } from './components/howtoapply/howtoapply.component';
import { DownloadprospectusComponent } from './components/downloadprospectus/downloadprospectus.component';

import { ViewTestReportGuideNoteComponent } from './components/view-test-report-guide-note/view-test-report-guide-note.component';
import { StartTestGuideNoteComponent } from './components/start-test-guide-note/start-test-guide-note.component';

import { RequestInterceptor } from './request.interceptor';

import { SlickCarouselModule } from 'ngx-slick-carousel';
import { AutocompleteLibModule } from 'angular-ng-autocomplete';

import { AppEmployerProfileComponent } from './components/employer-profile/employer-profile.component';
import { AppEmployerProfileSummaryComponent } from './components/employer-profile-summary/employer-profile-summary.component';
import { EmployerMyWorkComponent } from './components/employer-my-work/employer-my-work.component';
import { EditEmployerProfileComponent } from './components/edit-employer-profile/edit-employer-profile.component';
import { EmpMyProjectComponent } from './components/employer-my-project/employer-my-project.component';
import { EmpMyTaskComponent } from './components/emp-my-task/emp-my-task.component';
import { EmployerSupportTicketComponent } from './components/employer-support-ticket/employer-support-ticket.component';
import { EmployerActionCenterComponent } from './components/employer-action-center/employer-action-center.component';
import { MyApprenticeComponent } from './components/my-apprentice/my-apprentice.component';
import { StudentMyWorkComponent } from './components/student-my-work/student-my-work.component';
import { StudentMyWorkSummaryComponent } from './components/student-my-work-summary/student-my-work-summary.component';
import { StudentWalletComponent } from './components/student-wallet/student-wallet.component';
import { StudentKycComponent } from './components/student-kyc/student-kyc.component';
import { StudentWithdrawComponent } from './components/student-withdraw/student-withdraw.component';
import { StudentChannelAccountComponent } from './components/student-channel-account/student-channel-account.component';
import { EmployerNotificationComponent } from './components/employer-notification/employer-notification.component';
import { DownloadCenterComponent } from './components/download-center/download-center.component';
import { EmployerDownloadCenterComponent } from './components/employer-download-center/employer-download-center.component';
import { AcceptOtjJobModelComponent } from './components/modal-components/accept-otj-job-model/accept-otj-job-model.component';
import { ReturnOtjJobModalComponent } from './components/modal-components/return-otj-job-modal/return-otj-job-modal.component';
import { OtjPanelComponent } from './components/otj/otj-panel/otj-panel.component';
import { OtjTableComponent } from './components/otj/otj-table/otj-table.component';
import { OtjActivityComponent } from './components/otj/otj-activity/otj-activity.component';
import { LoaderComponent } from './components/loader/loader.component';
import { AttendanceComponent } from './components/otj/attendance/attendance.component';
import { ProgressionOtjComponent } from './components/otj/progression-otj/progression-otj.component';
import { VleRecordsComponent } from './components/otj/vle-records/vle-records.component';
import { AtLabRecordsComponent } from './components/otj/at-lab-records/at-lab-records.component';
import { EmpOtjComponent } from './components/otj/emp-otj/emp-otj.component';

import { AngularEditorModule } from '@kolkov/angular-editor';
import { MyAtlabComponent } from './components/atlab/my-atlab/my-atlab.component';
import { NgxStarRatingModule } from 'ngx-star-rating';
import { NgxEchartsModule } from 'ngx-echarts';
import { HrMinFormat } from './Models/hrminformat.pipe';
import { SprMainComponent } from './components/spr/spr-main/spr-main.component';
import { SprTableComponent } from './components/spr/spr-table/spr-table.component';
import { MonthlyReviewComponent } from './components/spr/monthly-review/monthly-review.component';
import { FormalReviewComponent } from './components/spr/formal-review/formal-review.component';
import { SkillScanTableComponent } from './components/spr/skill-scan-table/skill-scan-table.component';
import { MatrixComponent } from './components/spr/matrix/matrix.component';
import { ProgressionSprComponent } from './components/spr/progression-spr/progression-spr.component';
import { FormalReviewViewComponent } from './components/spr/formal-review/formal-review-view/formal-review-view.component';
import { MonthlyReviewViewComponent } from './components/spr/monthly-review/monthly-review-view/monthly-review-view.component';
import { SprSkillscanTableModalComponent } from './components/spr/spr-skillscan-table-modal/spr-skillscan-table-modal.component';

@NgModule({
  imports: [
    BrowserModule,
    CommonModule,
    SlickCarouselModule,
    FormsModule,
    AppRoutingModule,
    HttpClientModule,
    BrowserAnimationsModule,
    ToastrModule.forRoot(),
    ReactiveFormsModule,
    OwlModule,
    CarouselModule,
    NgxPaginationModule,
    JwPaginationModule,
    AutocompleteLibModule,
    CountdownModule,
    NgxFileDropModule,
    FlatpickrModule.forRoot(),
    CalendarModule.forRoot({
      provide: DateAdapter,
      useFactory: adapterFactory,
    }),
    CookieLawModule,
    NgxPayPalModule,
    BackButtonDisableModule.forRoot({
      preserveScrollPosition: true,
    }),
    NgxMaterialTimepickerModule,
    AngularEditorModule,
    NgxStarRatingModule,
    PDFExportModule,
    NgxEchartsModule.forRoot({
      echarts: () => import('echarts'),
    }),
  ],

  declarations: [
    AppComponent,
    MessagesComponent,
    HeaderComponent,
    FooterComponent,
    HomeComponent,
    AboutusComponent,
    BlogComponent,
    EventsComponent,
    TrainingsComponent,
    AboutcarouselComponent,
    HomecarouselComponent,
    BlogcarouselComponent,
    TrainingcarouselComponent,
    EventscarouselComponent,
    CertificatecarouselComponent,
    HomeCompaniesComponent,
    HomeBusinessComponent,
    HomeCategoriesComponent,
    HomeStudentsComponent,
    HomeCyberSecurityComponent,
    HomeProfessionalCoursesComponent,
    ContactComponent,
    ContactLocationComponent,
    ContactRegisterComponent,
    SupportComponent,
    SelectcourseComponent,
    SelectcoursecarouselComponent,
    ModaldialogComponent,
    LoginComponent,
    RegisterComponent,
    DashboardComponent,
    Header2Component,
    ProfileComponent,
    MytrainingComponent,
    AppliedTrainingsComponent,
    FavouriteTrainingsComponent,
    AllTrainingsComponent,
    CoursesComponent,
    CreateCertificatesComponent,
    MyCertificatesComponent,
    IndividualCourseComponent,
    MycoursesComponent,
    AdmissionComponent,
    BookInterviewComponent,
    ProfileSummaryComponent,
    EditProfileComponent,
    EmployeeHistoryComponent,
    PersonalStatementComponent,
    AdditionalDocumentsComponent,
    FeeComponent,
    ChecklistDocumentsComponent,
    QualificationsComponent,
    ProgressionComponent,
    SupportTicketsComponent,
    ComplaintComponent,
    FeedbacksComponent,
    AddQualificationModalComponent,
    AddEmployementHistoryModalComponent,
    AddPersonalStatementModalComponent,
    AddAdditionalDocumentModalComponent,
    CertificatesTabComponent,
    ComplaintComposeModalComponent,
    SupportTickectComposeComponent,
    OffthejobsComponent,
    AddOffthejobModalComponent,
    EditOffthejobModalComponent,
    EvidenceSampleModelComponent,
    PortfolioSubmitModelComponent,
    PortfolioComponent,
    CourseDownloadComponent,
    PortfolioEvidenceSubmitComponent,
    PlayComponent,
    AboutcourseComponent,
    TestComponent,
    QatestComponent,
    QuizComponent,
    SelectCertificateComponent,
    CmsComponent,
    ForgotpasswordComponent,
    SetpasswordComponent,
    FeedbackComponent,
    NotificationsComponent,
    PrivacypolicyComponent,
    CookiepolicyComponent,
    LmsInnerComponent,
    LessonCompleteModelComponent,
    LmsInnerAssignmentComponent,
    LmsInnerExamtypeComponent,
    ActionCenterComponent,
    CourseOverviewStandardsComponent,
    CourseFeedbackModelComponent,
    AlphabetOnlyDirective,
    ComplaintOpenComponent,
    UpdatePictureComponent,
    FilterPipe,
    TimeconverterPipe,
    HrMinFormat,
    DemoComponent,
    LmsInnerSidenavComponent,
    OpencertificateComponent,
    OnlineStatusComponent,
    BookInterviewNewComponent,
    BookInterviewTabsComponent,
    CourseExamComponent,
    OfferLetterComponent,
    ViewAllCoursesComponent,
    CartDetailsComponent,
    CheckoutComponent,
    ErrorPageComponent,
    StudywithusComponent,
    BlendedlearningComponent,
    TeachersComponent,
    HowtoapplyComponent,
    DownloadprospectusComponent,
    ViewTestReportSummaryComponent,
    HelpDeskComponent,
    LmsInnerCertificateComponent,
    HomeCourseCardComponent,
    HomeContentCardsComponent,
    ViewTestReportGuideNoteComponent,
    StartTestGuideNoteComponent,
    AppEmployerProfileComponent,
    AppEmployerProfileSummaryComponent,
    EmployerMyWorkComponent,
    EditEmployerProfileComponent,
    EmpMyProjectComponent,
    EmpMyTaskComponent,
    EmployerSupportTicketComponent,
    EmployerActionCenterComponent,
    MyApprenticeComponent,
    StudentMyWorkComponent,
    StudentMyWorkSummaryComponent,
    StudentWalletComponent,
    StudentKycComponent,
    StudentWithdrawComponent,
    StudentChannelAccountComponent,
    EmployerNotificationComponent,
    DownloadCenterComponent,
    EmployerDownloadCenterComponent,
    AcceptOtjJobModelComponent,
    ReturnOtjJobModalComponent,
    OtjPanelComponent,
    OtjTableComponent,
    OtjActivityComponent,
    LoaderComponent,
    AttendanceComponent,
    ProgressionOtjComponent,
    VleRecordsComponent,
    AtLabRecordsComponent,
    EmpOtjComponent,
    MyAtlabComponent,
    SprMainComponent,
    SprTableComponent,
    MonthlyReviewComponent,
    FormalReviewComponent,
    SkillScanTableComponent,
    MatrixComponent,
    ProgressionSprComponent,
    FormalReviewViewComponent,
    MonthlyReviewViewComponent,
    SprSkillscanTableModalComponent,
  ],
  providers: [
    {
      provide: HTTP_INTERCEPTORS,
      useClass: RequestInterceptor,
      multi: true,
    },

    CourseService,
    UserService,
    ExcelService,
  ],
  bootstrap: [AppComponent],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class AppModule {}
