import { Component, OnInit } from '@angular/core';
import { CourseService } from 'src/app/services/course.service';
import { APP_URLS } from 'src/app/Models/url';
import { DomSanitizer } from '@angular/platform-browser';
import { UserService } from 'src/app/services/user.service';

@Component({
  selector: 'app-my-atlab',
  templateUrl: './my-atlab.component.html',
  styleUrls: ['./my-atlab.component.css']
})
export class MyAtlabComponent implements OnInit {

  studentId: any;
  isLoading: boolean = true;
  FILE_PATH_LINODE = APP_URLS.FILE_PATH_LINODE;
  atlabList = [];
  currentTaget = 'all';
  isnoDataFound : boolean = false;
  noDataMsg: string;

  constructor(
    public service: CourseService,
    private sanitizer: DomSanitizer,
    private userSvc: UserService,
  ) { 
    this.studentId = JSON.parse(localStorage.getItem('userDetails'))._id;
  }

  ngOnInit(): void {
    this.getAtLabList(this.currentTaget);
  }

  getAtLabList( target : any ): void {
    this.currentTaget = target;
    this.isLoading = true;
    let url = APP_URLS.v1.getAtlabList + '?student_id=' + this.studentId + '&target=' + target;
    this.service.getCallwithHeader(url).subscribe(
      (data: any) => {
        this.isLoading = false;
        this.atlabList = data?.atlabList;
        this.isnoDataFound = ( this.atlabList.length ) ? false : true;
        if( this.atlabList.length ){
          this.atlabList.forEach(y => {
            y.coverImg = '';
            if( y.cover_image && y.cover_image.length > 0 ){
              y.coverImg = y.cover_image[0]
            }
            if( ! y.description ){
              y.description = '';
            }
          });
        } else {
          if (this.currentTaget == 'all') {
            this.noDataMsg = 'No AT LAB(s) found';
          } else if (this.currentTaget == 'completed') {
            this.noDataMsg = 'No AT LAB(s) are completed';
          } else if (this.currentTaget == 'pending') {
            this.noDataMsg = 'No pending AT LAB(s)';
          }
        }
      },
      (err) => {
        this.isLoading = false;
      }
    );
  }

  atLabActivityName : {};
  isOverAllLoadingAtLabLoading : boolean;
  atlabClick(data): void {
    this.atLabActivityName = data;
    this.isOverAllLoadingAtLabLoading = true;
    setTimeout(() => { this.isOverAllLoadingAtLabLoading = false; }, 500);
    
  }

  finishATLab(data: any) {
    let url = APP_URLS.v1.submitATLabRecords;
    let payload = {
      atlab_activity_ref: data?._id,
    };
    this.isOverAllLoadingAtLabLoading = true;
    this.userSvc.postCallwithHeaders(url, payload).subscribe(
      (res: any) => {
        if (res.toast?.code) {
          this.isOverAllLoadingAtLabLoading = false;
          document.getElementById('taskSubmitModal').click();
          this.userSvc.showSuccess('Successfully completed your AT Lab activity');
          this.getAtLabList(this.currentTaget);
        }
      },
      (error) => {
        this.userSvc.showError('applicaion failed');
      }
    );
  }

  convertDataintoHtml(value) {
    return this.sanitizer.bypassSecurityTrustHtml(value);
  }

}
