import { Component, OnInit, OnDestroy, Input, Output, OnChanges, EventEmitter, AfterViewInit, ElementRef, ViewChild } from '@angular/core';
import { ActivatedRoute, Router, ParamMap } from '@angular/router';
import { APP_URLS, tenantInfo } from '../../Models/url';
import { CourseService } from '../../services/course.service';
import { UserService } from '../../services/user.service';
import { ToastrService } from 'ngx-toastr';
import { CommonService } from '../../services/common.service'
import { TenantId } from '../../Models/url';
import { MyProfileService } from 'src/app/services/my-profile.service';
import { OwlOptions } from 'ngx-owl-carousel-o';
import * as $ from 'jquery';
import 'magnific-popup';


@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.css']
})
export class HomeComponent implements OnInit {
  tenantInfo:any = tenantInfo;
  showDialogReg = false;
  subscribe={
    email:""
  }
  coursetype: string;
  isOpenVideoDialog : boolean = false;
  student_Id = '';
  activeToken: boolean = false;
  userRole:any;
  slides = [{ id: 342, name : 'Maria D.', img : './assets/images/content/students/2.jpeg', occupation : 'ICTC Graduate', desc : "I started my journey with ICTC Training in 2015 and they gave me full support and confidence to start my training and career at the same time. I finished my course Diploma level 3 in Health and Social Care and started my first job as a Health Care Assistant in NHS. I am personally grateful to the Centre Manager Rupal Mehta and the whole administration for delivering the best possible standard of education." },
  { id: 453, name : 'Ellison Johnson', img : './assets/images/content/students/1.jpeg', occupation : 'ICTC Appentice', desc : "IC Training Centre was very useful to me as I was able to pursue my level 3 course while working and gaining experience at the same. The staff were really friendly and during my time there I learned a lot both theoretically and practically; I would recommend this place for anyone who’s looking to persue an apprenticeship."},
  { id: 846, name : 'Mellisa Doe', img : './assets/images/content/students/3.jpeg', occupation : 'ICTC Traineeship', desc : "Very professional environment providing very good guidance for students preparing them for a professional career in their chosen field of study." }];

  slideConfig = {
    "slidesToShow": 1,
    "slidesToScroll": 1,
    "nextArrow": "<div class='nav-btn next-slide'></div>",
    "prevArrow": "<div class='nav-btn prev-slide'></div>",
    "dots": true,
    "infinite": false
  };
  slickInit(e) {
  }

  breakpoint(e) {
  }

  afterChange(e) {
  }

  beforeChange(e) {
  }

  @ViewChild('video1') videoElement1:ElementRef;
  @ViewChild('video2') videoElement2:ElementRef;
  @ViewChild('video3') videoElement3:ElementRef;
  ngAfterViewInit(): void {
   if (this.videoElement1) {
       $(this.videoElement1.nativeElement).magnificPopup({ type: 'iframe' });
    }
    if (this.videoElement2) {
      $(this.videoElement2.nativeElement).magnificPopup({ type: 'iframe' });
    }
    if (this.videoElement3) {
        $(this.videoElement3.nativeElement).magnificPopup({ type: 'iframe' });
    }
  }

  constructor(private router: Router,
    private profileSvc:MyProfileService,
    private route: ActivatedRoute,
    public service: CourseService,
    private userSvc: UserService,
    private toastr: ToastrService,) { }

  ngOnInit(): void {

    this.userRole = localStorage.getItem('role');

    var userDetails = localStorage.getItem('userDetails');
    if( userDetails != undefined && typeof userDetails == 'string' ){
      var profileData = JSON.parse(userDetails);
      this.student_Id = profileData._id;
    }

    if (localStorage.getItem('token') && this.student_Id ) {
      this.activeToken = true
    } else {
      this.activeToken = false
    }

    this.coursetype=localStorage.getItem('courseSlug')
    if(this.coursetype){
      this.router.navigate(['/'+this.coursetype])
    }else{
      this.router.navigate(['/home'])
    }
  }

  registerOpen(){
    this.profileSvc.setForgotPop('register')
  }
  loadVideo(){
    this.isOpenVideoDialog = true;
  }
  submit(){
    let payload={
      email: this.subscribe.email,
    first_name: this.subscribe.email.split('@')[0],
    last_name: this.subscribe.email.split('@')[0],
    tenantId: TenantId,
    from_place: 'newsletter'

    }
    let url = APP_URLS.newlatter;

    this.userSvc.postCall(url,payload)
    .subscribe((data:any)=>{
      this.subscribe.email=""
      this.userSvc.showSuccess(data.respMessage)
    })
  }

  customOptions: OwlOptions = {
    loop: false,
    mouseDrag: false,
    touchDrag: false,
    pullDrag: false,
    dots: true,
    navSpeed: 700,
    navText: ['', ''],
    responsive: {
      0: {
        items: 1
      },
      400: {
        items: 2
      },
      740: {
        items: 1
      },
      940: {
        items: 1
      }
    },
    nav: false
  }

  profile() {
    if( this.userRole == 'employer'){
      this.router.navigate(['/employer-profile/emp-profile-summary'])
    } else {
      this.router.navigate(['/profile'])
    }
  }
}
