import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Subscription,forkJoin } from 'rxjs';
import { APP_URLS } from 'src/app/Models/url';
import { CourseService } from 'src/app/services/course.service';
import { MyProfileService } from 'src/app/services/my-profile.service';

@Component({
  selector: 'app-spr-table',
  templateUrl: './spr-table.component.html',
  styleUrls: ['./spr-table.component.css'],
  encapsulation: ViewEncapsulation.None,
})
export class SprTableComponent implements OnInit {
  signtureImage = APP_URLS.FILE_PATH_LINODE + 'signature/';
  isLoadingSpr = false;
  isLoadingMonthly = false;
  isLoadingFormal = false;
  isLoading = false;
  studentId = '';
  role = '';
  stsColor = '';
  displayName = '';
  sprDetails: any[] = [];
  monthlyReviewDetails: any;
  formalReviewDetails: any;
  singleReviewDetail: any;
  studentDetail: any = {};
  skillScanDetails: any = {};
  formalReviewDetailsModal: any = {};
  studentSign='';
  assessorsign='';
  employerSign='';

  routeRecords: Subscription;

  constructor(
    private route: ActivatedRoute,
    private profileSvc: MyProfileService,
    public service: CourseService
  ) {
    if (localStorage.getItem('role') == 'student') {
      var userDetails = JSON.parse(localStorage.getItem('userDetails'));
      this.studentId = userDetails._id;
      this.displayName = userDetails.displayName;
      this.role = 'student';
      this.getMonthlyReport();
      this.getFormalReport();
    }

    if (localStorage.getItem('role') == 'employer') {
      this.routeRecords = this.route.paramMap.subscribe((params) => {
        this.studentId = params.get('id');
        if (this.studentId != undefined) {
          this.getMonthlyReport();
          this.getFormalReport();
          this.getProfileDetails();
        } else {
          this.monthlyReviewDetails = [];
          this.formalReviewDetails = [];
        }
      });
      this.role = 'employer';
    }
  }

  ngOnInit(): void {}

  ngOnDestroy() {
    if (this.routeRecords) {
      this.routeRecords.unsubscribe();
    }
  }

  getProfileDetails() {
    let url = APP_URLS.profileDetails + this.studentId;
    this.profileSvc.getCallWithHeaders(url).subscribe(
      (res: any) => {
        this.studentDetail = res.details;
        this.displayName = this.studentDetail.displayName;

      },
      (err) => {}
    );
  }

  getMonthlyReport(): void {
    this.isLoadingMonthly = true;
    let url = APP_URLS.v1.monthlyReview + '/' + this.studentId;

    this.service.getCallwithHeader(url).subscribe(
      (data: any) => {
        this.isLoadingMonthly = false;
        if (data?.reviewDetails?.length > 0) {
          this.monthlyReviewDetails = data?.reviewDetails;
        } else {
          this.monthlyReviewDetails = [];
        }
      },
      (err) => {
        this.isLoadingMonthly = false;
        this.monthlyReviewDetails = [];
      }
    );
  }

  getFormalReport(): void {
    this.isLoadingFormal = true;
    let url = APP_URLS.v1.formalReview + '/' + this.studentId;
    this.service.getCallwithHeader(url).subscribe(
      (data: any) => {
        this.isLoadingFormal = false;
        if (data?.reviewDetails?.length > 0) {
          this.formalReviewDetails = data?.reviewDetails;
        } else {
          this.formalReviewDetails = [];
        }
      },
      (err) => {
        this.isLoadingFormal = false;
        this.formalReviewDetails = [];
      }
    );
  }

  openReviewModal(data, type): void {
    if (type == 'monthly') {
      this.singleReviewDetail = data?.monthlyReview;
    } else {
      this.singleReviewDetail = data?.formalReview;
    }
    this.getSignature(data?.studentInfo?.Signature,data?.employeeInfo?.signature,data?.employerInfo?.signature);

    this.singleReviewDetail['declarationByStudent'] =
      data?.declarationByStudent;
    this.singleReviewDetail['declarationByEmployee'] =
      data?.declarationByEmployee;
    this.singleReviewDetail['apprenticeSignature'] =
      data?.studentInfo?.Signature;
    this.singleReviewDetail['employerInfo'] = data?.employerInfo;

    // this.getSignature(data?.employeeInfo?.signature);
    // this.getSignature(data?.employerInfo?.signature);
  }

  closeReviewModal(): void {
    this.singleReviewDetail = undefined;
  }

  compareDate(data, key) {
    var date1 = new Date();
    var date2 = new Date(data[key]?.reviewdate);

    date1.setHours(0, 0, 0, 0);
    date2.setHours(0, 0, 0, 0);

    if (data.declarationByEmployee) {
      if (data.declarationByStudent && data[key].declarationByEmployer) {
        return 'Completed';
      } else {
        return 'Pending';
      }
    } else {
      if (date1 > date2) {
        return 'Over due';
      } else if (date1 < date2) {
        return 'Due';
      } else {
        return 'Due';
      }
    }
  }

  openSkillScanModal(index): void {
    this.getSkillScanReport(index);
  }

  getSkillScanReport(index): void {
    this.isLoading = true;
    var selectedIndex = index;
    let url = APP_URLS.v1.skillScanReport + '/' + this.studentId;

    this.service.getCallwithHeader(url).subscribe(
      (data: any) => {
        this.isLoading = false;
        if (data?.sprReport?.length > 0) {
          this.skillScanDetails = data?.sprReport;
        } else {
          this.skillScanDetails = [];
        }

        if (data?.formalReviewDetails?.length > 0) {
          this.formalReviewDetailsModal = data?.formalReviewDetails;
          this.formalReviewDetailsModal = this.formalReviewDetailsModal.filter(
            (data, index) => {
              return selectedIndex >= index;
            }
          );
        } else {
          this.formalReviewDetailsModal = [];
        }
      },
      (err) => {
        this.skillScanDetails = [];
        this.formalReviewDetailsModal = [];
      }
    );
  }

  closeSkillScanModal(): void {
    this.skillScanDetails = [];
    this.formalReviewDetailsModal = [];
  }
  getSignature(src1,src2,src3): any {

    let url = APP_URLS.v1.getEncodedUrl;

    var stuSign = this.service.postCallwithHeaders(url, {
      path: this.signtureImage + src1,
    });

    var assessorSign = this.service.postCallwithHeaders(url, {
      path: this.signtureImage + src2,
    });

    var employerSign = this.service.postCallwithHeaders(url, {
      path: this.signtureImage + src3,
    });
      forkJoin([stuSign, assessorSign, employerSign]).subscribe(
        (results: any) => {

          if (this.signtureImage + src1 && results[0]) {
            this.studentSign = 'data:image/jpeg;base64,' + results[0]?.encodeData;
          }
          if (this.signtureImage + src2 && results[1]) {
            this.assessorsign ='data:image/jpeg;base64,' + results[1]?.encodeData;
          }
          if (this.signtureImage + src3 && results[2]) {
            this.employerSign ='data:image/jpeg;base64,' + results[2]?.encodeData;
          }
        }
      );
  }

  getStatusColor(data) {
    if (data == 'Over due') {
      let obj = {
        color: '#f00',
      };
      return obj;
    } else if (data == 'Due') {
      let obj = {
        color: '#00f',
      };
      return obj;
    } else if (data == 'Pending') {
      let obj = {
        color: '#800080',
      };
      return obj;
    } else if (data == 'Completed') {
      let obj = {
        color: '#118911',
      };
      return obj;
    } else {
    }
  }
}
