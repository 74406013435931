import { Component, Input, OnInit, ViewEncapsulation } from '@angular/core';
import { APP_URLS } from 'src/app/Models/url';

@Component({
  selector: 'app-formal-review-view',
  templateUrl: './formal-review-view.component.html',
  styleUrls: ['./formal-review-view.component.css'],
  encapsulation: ViewEncapsulation.None,
})
export class FormalReviewViewComponent implements OnInit {
  @Input() singleReviewDetail: any;
  signtureImage = APP_URLS.FILE_PATH_LINODE + 'signature/';
  @Input() displayName = '';
  @Input() studentSign = '';
  @Input() employerSign = '';
  @Input() assessorsign = '';

  constructor() {}

  ngOnInit(): void {
    //console.log(this.studentSign);
  }
}
